<template>
  <div>
    <RecursiveView
      v-if="relationship['recursive']"
      :_search_component="_search_component"
      :relationship="relationship"
      :params.sync="params"
      :disabled="disabled || $d.relIsViewOnly(relationship)"
      :mode="'flattened'"
      :find_button_visibility="find_button_visibility"
      :show_action_bar="_.get(find_button_visibility, [relationship['relationship_name']], true)"
      :title="title"
      :_exclude_fields="_exclude_fields"
    >
      <template v-slot:[slotName]="{ relationship, params, disabled }">
        <!-- {{relationship['relationship_name']}} -->
        <slot :name="slotName" :relationship="relationship" :params.sync="params" :disabled="disabled"></slot>
      </template>
    </RecursiveView>
    <RelationshipView
      v-else
      :_search_component="_search_component"
      :view_only="disabled || $d.relIsViewOnly(relationship)"
      :fixed_params="fixed_params"
      :relationship="relationship"
      :params.sync="params"
      :_show_selecting_search="typeof _show_selecting_search !== 'undefined' ? _show_selecting_search : params['id'] ? false : !fixed_params || fixed_params[$d.getRelationAlias(relationship)] == null"
      @update:_show_selecting_search="(newValue) => $emit('update:_show_selecting_search', newValue)"
      :title="title"
      :_exclude_fields="_exclude_fields"
    ></RelationshipView>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  components: {
    RelationshipView: () => import('./RelationshipView'),
    RecursiveView: () => import('./RecursiveView'),
  },
  props: {
    _search_component: {},
    title: {
      type: String,
    },
    relationship: {
      type: Object,
    },
    params: {
      type: Object,
      required: true,
    },
    fixed_params: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    find_button_visibility: {
      type: Object,
    },
    _exclude_fields: {},
    _show_selecting_search: {
      type: Boolean,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    slotName() {
      return this.relationship['from']['class_name'] + '_' + this.relationship['relationship_name'];
    },
  },
  created() {
    this._ = _;
  },
  mounted() {},
  methods: {},
};
</script>
